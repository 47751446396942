import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ApplicationRoutes } from 'src/app/configs/app-route.config';
import { GlobalObjectService } from '../../shared/services/global-object.service';
import { AuthService } from '../state/auth-state/auth.service';
import { AppApiConfiguration as apiOf } from 'src/app/configs/app-api.config';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ToastService } from '../services/toast.service';
// import { PopupErrorComponent } from 'src/app/shared/components/popups/popup-error/popup-error.component';
// import { PopupRecommendComponent } from 'src/app/shared/components/popups/popup-recommend/popup-recommend.component';

@Injectable()
export class UnAuthenticatedResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private globalObject: GlobalObjectService,
    private authService: AuthService,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(
        () => {},
        (apiError) => {
          if (apiError instanceof HttpErrorResponse && apiError.status) {
            if (apiError.status == 403){
              // this.router.navigate([ApplicationRoutes.auth.deActivated]);
            }
            if (apiError.status == 412 && (this.router.url && this.router.url.includes('product') && apiError.url?.includes('Permission'))){
              flattenErrorsCollection(apiError.error.errors).map((err) => this.toastService.error(err))
              setInterval(() => {
                this.router.navigate([ApplicationRoutes.profile.advanceProfileTab])
              }, 1000);
            //   const dialogRefRecommend: DialogRef = this.dialogService
            //   .open({
            //     content: PopupRecommendComponent
            //   });
            //   const RecommendInfo = dialogRefRecommend.content.instance as PopupRecommendComponent;
            //   RecommendInfo.headerTitle = apiError.error.title || 'Attention Here!!!'
            //   RecommendInfo.suggestionList = flattenErrorsCollection(apiError.error.errors)
            //   RecommendInfo.buttonText = `Go back`
            //   RecommendInfo.buttonTextSecond = `Complete Your Profile`
            //   dialogRefRecommend.result.subscribe((data: any) => {
            //     if (data instanceof DialogCloseResult) {
            //     } else {
            //       if(data.isAction){
            //         this.router.navigate([ApplicationRoutes.profile.advanceProfileTab], {
            //           queryParams: {
            //             redirectUrl: this.router.url,
            //             returnUrl: this.globalObject.getLocation()?.pathname,
            //           },
            //         });
            //       }
            //       else{
            //         this.router.navigate([ApplicationRoutes.appRoot])
            //       }
            //     }
            //   })            
            }
            if (apiError.status !== 401) return;
            if(apiError.url !== apiOf.auth.validateRefreshToken){
              this.authService.refreshTokenValidate()
            }
          }
        }
      )
    );
  }
}

const flattenErrorsCollection = (errors: {
  [key: string]: string[];
}): string[] => {
  const errorPerProp = Object.values(errors);
  if (errorPerProp && errorPerProp.length)
    return errorPerProp.reduce((prev, current) => prev.concat(current), []);
  return ["It's not you. We are facing some issue. Please try again later."];
};
