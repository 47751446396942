import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ProfileComponent } from './pages/profile-html/profile.component';
import { BasicProfileComponent } from './pages/profile-html/basic-profile/basic-profile.component';

export const routes: Routes = [
  {
    path: 'category/:lookupName/:lookupCode',
    loadComponent: () => import('./pages/category-page/category-page.component').then(c => c.CategoryPageComponent)
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/all-courses/all-courses.component').then(c => c.AllCoursesComponent)
  },
  {
    path: 'product-detail',
    loadComponent: () => import('./pages/product-detail/product-detail.component').then(c => c.ProductDetailComponent)
  },
  {
    path: 'profile-html',
    component: ProfileComponent,
    children: [
      {
        path: '',  // Default route, redirects to basic profile
        pathMatch: 'full',
        redirectTo: 'basic'
      },
      {
        path: 'basic',
        loadComponent: () => import('./pages/profile-html/basic-profile/basic-profile.component').then(c => BasicProfileComponent)
      },
      {
        path: 'advanced',
        loadComponent: () => import('./pages/profile-html/basic-profile/basic-profile.component').then(c => BasicProfileComponent)
      },
      {
        path: 'company',
        loadComponent: () => import('./pages/profile-html/basic-profile/basic-profile.component').then(c => BasicProfileComponent)
      },
      {
        path: 'settings',
        loadComponent: () => import('./pages/profile-html/basic-profile/basic-profile.component').then(c => BasicProfileComponent)
      },
    ]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(c => c.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(c => c.LandingModule)
  },
  {
    path: 'policies',
    loadChildren: () => import('./pages/policies/policies.module').then(c => c.PoliciesModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product-forms/product-forms.module').then(c => c.ProductFormsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user-pages/user-pages.module').then(c => c.UserPagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'learn',
    loadChildren: () => import('./learn/learn.module').then(c => c.LearnModule)
  },
  {
    path: 'seminar',
    loadChildren: () => import('./seminar/seminar.module').then(c => c.SeminarModule)
  },
  {
    path: 'mentor',
    loadChildren: () => import('./mentor/mentor.module').then(c => c.MentorModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(c => c.BlogModule)
  },
  {
    path: 'job',
    loadChildren: () => import('./job/job.module').then(c => c.JobModule)
  },
  {
    path: 'internship',
    loadChildren: () => import('./internship/internship.module').then(c => c.InternshipModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/landing/landing.module').then(c => c.LandingModule)
      },
    ],
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
];
